import {Component, Inject, Vue} from "vue-property-decorator";
import {configOptions} from "@/plugins/keycloak-config";
import {AppConstant} from "@/app.constant";
import store from "@/store";

@Component({
})
export default class HeaderComponent extends Vue {

    public drawer: any = false
    public group: any = null

    mounted(): void {
    }

    login(): void {
        this.$keycloak.login();
    }

    logout(): void {
        this.$keycloak.logout({ redirectUri : configOptions.logoutRedirect });
        localStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
        sessionStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
        store.commit("logout");
        store.dispatch("logout").then();
    }

}