import {Component, Inject, Vue} from "vue-property-decorator";
import _axiosInstance from "@/plugins/axios";

@Component({
})
export default class FooterComponent extends Vue {

    gitInfo: Map<String,String> = new Map<String, String>();

    public mounted() {
        _axiosInstance.get("git-info").then(res => {
            this.gitInfo = res.data;
        })
    }
}