import {Component, Vue} from "vue-property-decorator";
import {WebResource} from "@/components/WebResource";
import _axiosInstance from "@/plugins/axios";

@Component({
})
export default class UpdateContentComponent extends Vue {

    uniqueDisabled = false
    webResource: WebResource = {}
    content: Map<string, string> = new Map<string, string>()
    public contentFields = [];
    snackbar = false;
    snackbarMessage = '';
    isExisting(): boolean {
        return !!this.$route.query.pageId
    }

    loadContent(): void {
        if (this.isExisting()) {
            this.uniqueDisabled = true
            _axiosInstance.get("api/content/" + this.$route.query.pageId)
                .then(response => {
                    const content = response.data;
                    console.log(response.data);
                    this.webResource = content
                    this.content = content.jsonContent
                    console.log(this.content)
                    document.title = this.webResource.title as string
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }

    handleContent(): void {
        if (this.isExisting()) {
            this.updateContent()
        } else {
            this.persistContent()
        }
    }

    updateContent(): string {
        console.log("update content")
        this.webResource.jsonContent = this.content
        _axiosInstance.patch('api/content/' + this.$route.query.pageId, this.webResource)
            .then(response => {
                const msg = response.data.message;
                console.log(msg);
                this.snackbarMessage = 'Content updated.';
                this.snackbar = true;
            })
            .catch(error => {
                console.log(error);
            });
        return "updated"
    }

    persistContent(): string {
        this.webResource.jsonContent = this.content
        _axiosInstance.post('/api/content', this.webResource)
            .then(response => {
                const res = response.data;
                this.$router.push('update-content?pageId=' + res.webResourceId).then()
                this.loadContent()
                console.log(res);
                alert('Content saved.')
            })
            .catch(error => {
                console.log(error);
            });
        return "saved"
    }

    mounted(): void {
        _axiosInstance.get("api/template").then(res => {
            this.contentFields = res.data.content[0].pageStructureJson.fields.sort((field:any) => {field.order})
        })
        this.loadContent()

    }
}
