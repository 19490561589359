
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {AppConstant} from "@/app.constant";


// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const localRoot = "http://localhost:8081"
const config = {
  baseURL: (window.location.origin === localRoot ? "http://localhost:8080" : window.location.origin) + "/" || "",
  timeout: 60 * 1000, // Timeout
  headers:{
  }
};

const _axiosInstance = axios.create(config);

export default _axiosInstance;
