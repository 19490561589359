<template>
    <div>
        {{this.$route.meta.title}}
    </div>
</template>

<script>
    export default {
        name: "view-content"
    }
</script>

<style scoped>

</style>