import {Component, Vue, } from "vue-property-decorator";
import _axiosInstance from "@/plugins/axios";

@Component
export default class LabTestComponent extends Vue {

    uniqueName= ""
    description= ""
    title= ""
    contentKeys: string[] = []
    content: Map<String,String> = new Map<String, String>()
    public contentFields = [];
    pageId= null

    breadcrumbs = [
        {
            text: 'Back to Search',
            disabled: false,
            to: '/',
        }
    ]

    loadContent(): void {
        _axiosInstance.get("api/content/" + this.$route.query.id)
            .then(response => {
                const content = response.data
                console.log(response.data)
                this.pageId = content.webResourceId
                this.uniqueName = content.uniqueName
                this.title = content.title
                this.content = content.jsonContent
                document.title = this.title
                this.breadcrumbs.push(
                    {
                        text: this.title,
                        disabled: true,
                        to: ''
                    }
                )
            })
            .catch(error => {
                console.log(error)
            });
    }

    mounted():void{
        _axiosInstance.get("api/template").then(res => {
            this.contentFields = res.data.content[0].pageStructureJson.fields.sort((field:any) => {field.order})
        })
        this.loadContent()
    }
}