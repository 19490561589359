import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import {VueMaskDirective} from 'v-mask';
import * as kc from "@/plugins/keycloak-config";
import {AppConstant} from "@/app.constant";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import _axiosInstance from "@/plugins/axios";
import {KeycloakConfig, KeycloakInstance} from "keycloak-js";

Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false
kc.config().then(config => {
    const keycloak = kc.keycloak;
    const keycloakOptions = kc.keycloakOptions;
    keycloak.init({
        onLoad: "check-sso",
        checkLoginIframe: false
    }).then((auth: boolean) => {
        console.log(auth)
        if (!auth) {
            console.log("Logging out if not already.")
            localStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
            sessionStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
            store.commit("logout");
            store.dispatch("logout").then();
        } else {
            localStorage.setItem(AppConstant.AUTH_TOKEN_NAME, <string>keycloak.token);
            sessionStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
            retrieveDetails(auth, keycloak, keycloakOptions);
        }
    }).then(res => {
        new Vue({
            vuetify,
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    }).catch(() => {
        console.log("Authenticated Failed");
    });
})


async function retrieveDetails(auth: boolean, keycloak: KeycloakInstance, keycloakOptions: KeycloakConfig) {
    await keycloak.loadUserProfile().then((res: any) => {
        console.log(`CHECKING TOKEN>>>>`)
        store.commit("addKeycloak", keycloak)
        store.commit("login", keycloak.token)
        store.commit("addUsername", res.username)
        store.commit("addAuthenticated", auth)
        console.log("UPDATING AUTH>>>>>>>")
        store.commit("addRoles", (keycloak.resourceAccess as any)[keycloakOptions.clientId].roles)
    })
}

_axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const authToken = localStorage.getItem(AppConstant.AUTH_TOKEN_NAME) || sessionStorage.getItem(AppConstant.AUTH_TOKEN_NAME);
    if (authToken) {
        refreshKeycloakToken(kc.keycloak);
        if(request.headers)
            request.headers.Authorization = `Bearer ${localStorage.getItem(AppConstant.AUTH_TOKEN_NAME) || sessionStorage.getItem(AppConstant.AUTH_TOKEN_NAME)}`
    }
    return request
}, (error: any) => {
    return Promise.reject(error).then();
})

_axiosInstance.interceptors.response.use((response) => {
        return response;
    },
    (error) => {
      const status = error.status || error.response.status;
      if (status === 403 || status === 401) {
        kc.keycloak.logout();
      }
        return Promise.reject(error).then();
    })

function refreshKeycloakToken(keycloak: KeycloakInstance) {
    keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
            console.log('Token refreshed: ' + refreshed);
            localStorage.setItem(AppConstant.AUTH_TOKEN_NAME, <string>keycloak.token);
            sessionStorage.removeItem(AppConstant.AUTH_TOKEN_NAME);
        } else {
            let timeUntilExpiration = 0;
            const keycloakTokenParsedExp = keycloak?.tokenParsed?.exp ? keycloak?.tokenParsed?.exp : 0;
            const keycloakTimeSkew = keycloak?.timeSkew ? keycloak?.timeSkew : 0
            timeUntilExpiration = Math.round(keycloakTokenParsedExp + keycloakTimeSkew - new Date().getTime() / 1000)
            console.log('Token not refreshed, valid for '
                + timeUntilExpiration + ' seconds');
        }
    }).catch(() => {
        console.log('Failed to refresh token');
    });
}

declare module 'vue/types/vue' {
    interface Vue {
        $keycloak: typeof Vue.prototype.$keycloak
    }
}
