import {Component, Vue, Watch} from "vue-property-decorator";
import {DataOptions} from "vuetify";
import {WebResource} from "@/components/WebResource";
import _axiosInstance from "@/plugins/axios";

@Component
export default class AdminContentListComponent extends Vue {

    public headers: any = [
        {
            text: 'Title',
            align: 'start',
            sortable: true,
            filterable: true,
            value: 'title',
        },
        {
            text: 'Modified',
            align: 'start',
            sortable: true,
            value: 'md',
        },
        {text: 'Actions', value: 'actions', sortable: false},
    ]
    public itemKey: any = null
    public options: DataOptions = {
        itemsPerPage: 15,
        page: 1,
        sortBy: ['title'],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    }
    public totalElements: any = 0
    public webResourceList: WebResource[] = []
    public loggedIn: Boolean = false
    public admin: Boolean = false
    public tableLoading: Boolean = true
    public title: string = '';
    public searchField: string = '';
    public searchValue: string = '';
    public titleSearch: string = ''
    public searchKeyList = [];

    @Watch("options",
        {deep: true}
    )
    public loadTable(): void {
        this.tableLoading = true
        const sortString = this.determineSort()
        _axiosInstance.get( "api/content/search/findJsonbContentBySingleField",{
            params: {
                page: this.options.page - 1,
                size: this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500,
                searchValue:this.searchValue ? this.searchValue : '',
                title: this.titleSearch ? this.titleSearch : '',
                sort: sortString
            },
        })
            .then(response => {
                this.webResourceList = response.data.content
                this.totalElements = response.data.page.totalElements
                this.tableLoading = false
            })
            .catch(error => {
                console.log(error)
            });
    }

    public determineSort(): String {
        console.log(this.options)
        return (this.options.sortBy[0] == 'title' ? 'title' : this.options.sortBy[0]) + ',' + (this.options.sortDesc[0] ? 'desc' : 'asc')
    }

    public deleteContent(item: any): Boolean {
        if (!confirm('Are you sure?')) return false
        _axiosInstance.delete('api/content/' + item.webResourceId)
            .then(response => {
                const object = response.data
                this.loadTable()
                console.log(object);
            })
            .catch((error:any) =>  {
                console.log(error);
            });
        return true
    }

    public mounted (): void {
        _axiosInstance.get("api/template").then(res => {
            this.searchKeyList = res.data.content[0].pageStructureJson.fields
                .sort((field:any) => {field.order})
        })
        this.loadTable()
    }
}