import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
  },
  getters:{
    loggedIn:(state)=> {
      return state.authenticated
    },
    keycloak:(state)=> {
      return state.keycloak
    },
  },
  state: {
    userIdentity: null,
    authenticated: false,
    roles: [],
    username:null,
    keycloak: {}
  },
  actions: {
    addKeycloak(context,keycloak){
      context.commit("login",keycloak)
    },
    login(context,userIdentity){
      context.commit("login",userIdentity)
    },
    addRoles(context,roles){
      context.commit("addRoles",roles)
    },
    addUsername(context,username){
      context.commit("addUsername",username)
    },
    addAuthenticated(context,auth){
      context.commit("addAuthenticated",auth)
    },
    logout(context){
      context.commit("logout")
    }
  },
  mutations: {
    addKeycloak(state,keycloak){
      state.keycloak = keycloak
    },
    login(state,userIdentity){
      state.userIdentity = userIdentity
    },
    logout(state){
      state.userIdentity = null
      state.authenticated = false
      state.roles = []
      state.username = null
      state.keycloak = {}
    },
    addRoles(state,roles){
      state.roles = roles
    },
    addUsername(state,username){
      state.username = username
    },
    addAuthenticated(state,authenticated){
      state.authenticated = authenticated
    },
  },
})

export default store
